import { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import classnames from 'classnames';

import Snow from './Snow';

import './App.css';
import mainImage from './images/6.jpeg';
import loadingGif from './images/loading-wedding-animation.gif';
import mapIcon from './images/map.png';
import naviIcon from './images/navi.png';

import image1 from './images/1.jpeg';
import image2 from './images/2.jpeg';
import image3 from './images/3.jpeg';
import image5 from './images/5.jpeg';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

function App() {
  const [loaded, setLoaded] = useState(false);
  const [containerReady, setContainerReady] = useState(false);

  useEffect(() => {
    if (window.Kakao && !window.Kakao.isInitialized()) {
      window.Kakao.init('6dac7d69098fe4ddbcb7d9f752f5180b');
    }

    if (window.kakao && loaded) {
      const container = document.querySelector('.map');
      const options = {
        center: new window.kakao.maps.LatLng(37.40050, 127.11146),
        level: 3,
      };

      const map = new window.kakao.maps.Map(container, options);

      const markerPosition = new window.kakao.maps.LatLng(37.40050, 127.11146);

      const marker = new window.kakao.maps.Marker({
        position: markerPosition,
        clickable: true,
      });

      marker.setMap(map);

      window.kakao.maps.event.addListener(marker, 'click', () => window.open('https://place.map.kakao.com/26903392'));
    }

    setTimeout(() => {
      setLoaded(true);

      setTimeout(() => {
        setContainerReady(true);
      }, 10);
    }, 2000);
  }, [loaded]);

  function navi() {
    return window.Kakao.Navi.start({
      name: 'W스퀘어 컨벤션',
      x: 127.11146888175774,
      y: 37.4005082182319,
      coordType: 'wgs84',
    })
  }

  function share() {
    if (!navigator.share) {
      return;
    }

    return navigator.share({
      title: 'Wedding Invitation',
      url: window.location.href,
    });
  }

  return (
    <div className="App">
      {!loaded && (
        <div className="loading">
          <img src={loadingGif} alt="Loading" />
        </div>
      )}
      <div className={classnames('container', {
        show: loaded,
        'container--visible': containerReady,
      })}>
        <div className="main">
          <div className="main-title">
            <div className="wedding-invitation">
              Wedding<br />Invitation
            </div>
            <div className="v-line"></div>
            <div className="name">
              권태민<br />서일희
            </div>
          </div>
          <img className="main-image" src={mainImage} alt="main" />
          <div className="main-description">
            <div className="main-date">
              2021·11·27(土) 오후 1:00
            </div>
            <div className="h-line"></div>
            <div className="main-location">
              판교 W스퀘어컨벤션 그레이스홀
            </div>
          </div>
        </div>
        <div className="dot">
        ·
        </div>
        <div className="parents-container">
          <div className="parents">
            <span className="parents-title">신랑측 혼주</span>
            <span>권중열 · 이정숙</span>
          </div>
          <div className="parents">
            <span className="parents-title">신부측 혼주</span>
            <span>서병현 · 최경숙</span>
          </div>
        </div>
        <div className="invitation-card">
          새로운 마음과 새 의미를 간직하며 저희 두 사람이 새 출발의 첫 걸음을 내딛습니다. 좋은 꿈, 바른 뜻으로 올바르게 살 수 있도록 축복과 격려주시면 더없는 기쁨으로 간직하겠습니다.<br /><br />
          코로나 바이러스로 인한 사회적 거리두기가 장기화됨에 따라 이런 상황에서 초대하게 된 저희도, 초대를 받으신 분들도 마음이 무거울 거라 생각합니다. 참석하지 못하시더라도 부담 갖지 마시고, 멀리서 나마 축하해 주시면 감사하겠습니다.
        </div>
        <div className="dot">
        ·
        </div>
        <div className="carousel">
          <Carousel autoPlay={true} showStatus={false} showThumbs={false} interval={5000}>
            <div>
              <img src={image3} alt="이미지" />
            </div>
            <div>
              <img src={image1} alt="이미지" />
            </div>
            <div>
              <img src={image5} alt="이미지" />
            </div>
            <div>
              <img src={image2} alt="이미지" />
            </div>
          </Carousel>
        </div>
        <div className="map-container">
          <div className="map-title">
            찾아오시는 길
          </div>
          <div className="h-line"></div>
          <div className="map"></div>
          <div className="map-buttons">
            <div className="button" onClick={() => window.open('https://map.kakao.com/link/to/26903392')}>
              <img src={mapIcon} alt="카카오맵으로 길찾기" />
              <span>카카오맵</span>
            </div>
            <div className="button" onClick={navi}>
              <img src={naviIcon} alt="카카오내비로 길찾기" />
              <span>카카오내비</span>
            </div>
          </div>
          <div className="bmw">
            <div className="metro bmw-element">
              <span className="bmw-title">지하철안내</span>
              <span>신분당선 판교역 1번출구 셔틀버스 이용<br />(10분 간격 운행)</span>
            </div>
            <div className="bus bmw-element">
              <span className="bmw-title">버스안내</span>
              <span>390번: 동안교 하차</span>
              <span>4000번: 테크노육교 하차</span>
              <span>101, 330, 350, 390, 4000번: 차그룹컨소시엄 하차</span>
            </div>
            <div className="car bmw-element">
              <span className="bmw-title">자가용</span>
              <span>경부고속도로 판교IC(톨게이트) 차출 후 좌회전, 약 700m 직진하여 사거리에서 우회전(SK 주유소 뒤)</span>
            </div>
          </div>
        </div>
        {navigator.share && (
          <div className="share" onClick={share}>
            청첩장 공유하기
          </div>
        )}
      </div>
      <Snow />
    </div>
  );
}

export default App;
